/* bootstrap overwrite */

// Options
$enable-caret: false;
$enable-validation-icons: false;
$enable-deprecation-messages: false;
$enable-rounded: false;

// Fonts
$font-family-sans-serif: 'museo-sans', 'Helvetica Neue', 'Arial', sans-serif;
$font-weight-base: 300;
$font-weight-bolder: 700;
$font-size-base: 16px;
$small-font-size: 14px;
$line-height-base: 1.4;
$line-height-lg: $line-height-base;
$line-height-sm: $line-height-base;

$lead-font-size: 26px;

$headings-line-height: $line-height-base;
$headings-font-weight: 700;
$h1-font-size: $font-size-base * 2.75;
$h2-font-size: $font-size-base * 2.25;
$h3-font-size: $font-size-base * 2;
$h4-font-size: $font-size-base * 1.375;
$h5-font-size: $font-size-base * 1.125;

$alert-padding-x: $font-size-base * 0.75;
$alert-padding-y: $font-size-base * 1.25;

// Colors

// grays + neutral
$color-white: #fff;
$color-gray-100: #f8f9f9;
$color-gray-200: #f4f6f7;
$color-gray-600: #657785;
$color-gray-700: #576a77;
$color-gray-800: #485c6a;

$color-icegray-500: #d3ddf2;
$color-icegray-600: #c9d1e3;

// primary
$color-blackblue: #0b0e29;
$color-darkblue: #001f47;

$color-skyblue-200: #21b5ea;
$color-skyblue-300: #12a7de;
$color-skyblue-400: #0b98cb;
$color-skyblue-500: #0584b8;
$color-skyblue-600: #0075a3;

$color-green-50: #f8fff0;
$color-green-100: #e8fad2;
$color-green-200: #cde8ac;
$color-green-300: #b6d98b;
$color-green-400: #a0c96d;
$color-green-500: #69a438;
$color-green-600: #458608;
$color-green-700: #3e7707;
$color-green-800: #366706;

// secondary
$color-darkred: #9e092f;
$color-red: #e4003a;
$color-darkgreen: #007a62;
$color-yellow: #ffce44;
$color-lightbrown: #aea693;
$color-darkbrown: #7f6f59;

// Colors (bootstrap overwrite)
$success: $color-green-500;
$info: $color-skyblue-200;
$warning: $color-yellow;
$danger: $color-red;

// Shadows
$box-shadow: 0 2px 10px rgba(101, 119, 133, 0.6);
$box-shadow-ring: 0 0 0 2px var(--focus-offset-color, $color-white),
  0 0 0 calc(2px + 2px) var(--focus-color, $color-icegray-600);
$box-shadow-ring-offset-4: 0 0 0 4px var(--focus-offset-color, $color-white),
  0 0 0 calc(2px + 4px) var(--focus-color, $color-icegray-600);

// Gradients
$gradient-primary: linear-gradient(122.45deg, #a0c96d 14.06%, #21b5ea 78.85%);
$gradient-primary-hover: linear-gradient(139.7deg, #21b5ea -18.56%, #a0c96d 116.63%);
$gradient-blackblue-darkblue: linear-gradient(
  135deg,
  $color-blackblue 0%,
  $color-blackblue 50%,
  $color-darkblue 50%,
  $color-darkblue 100%
);

// Transitions
$transition-focus-ring: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

// Icons
$icon-arrow-down: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyMSAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTAuNTAyNjQ0IDE3LjQ2NjRMOS41NzIwNSAyMy44MDdDOS44NTg0NSAyNC4wMTQ5IDEwLjI0MDMgMjQuMDE0OSAxMC41NTA2IDIzLjgwN0wxOS42NDM5IDE3LjQ2NjRDMjAuMDczNSAxNy4xODA1IDIwLjE5MjggMTYuNTU2OCAxOS45MDY0IDE2LjExNTFDMTkuNjIgMTUuNjczMyAxOS4wNzExIDE1LjUxNzQgMTguNjY1MyAxNS44MjkyTDExLjAwNDEgMjEuMTgyNEwxMS4wMDQxIDEuMDEzNDNDMTEuMDA0MSAwLjQ0MTczNSAxMC41NzQ1IC0yLjk0NDEzZS0wNSAxMC4wNzMzIC0yLjk0ODUyZS0wNUM5LjU3MjA1IC0yLjk1MjkxZS0wNSA5LjE0MjQ0IDAuNDY3NzIxIDkuMTQyNDQgMS4wMTM0M0w5LjE0MjQ0IDIxLjE4MjRMMS40ODExOCAxNS44MjkyQzEuMzM3OTggMTUuNzI1MyAxLjE3MDkyIDE1LjY3MzMgMS4wMDM4NSAxNS42NzMzQzAuNzE3NDQ2IDE1LjY3MzMgMC40MDcxNzggMTUuODI5MiAwLjI0MDExMSAxNi4xMTUxQy0wLjAyMjQyNiAxNi41NTY4IDAuMDk2OTA3MyAxNy4xODA1IDAuNTAyNjQ0IDE3LjQ2NjRaIiBmaWxsPSJjdXJyZW50Q29sb3IiLz4KPC9zdmc+Cg==);
$icon-location: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEwLjAxMyAxMS4zODgzQzEwLjcyNTYgMTEuMzg4MyAxMS4zMDMzIDEwLjgxMDYgMTEuMzAzMyAxMC4wOTc5QzExLjMwMzMgOS4zODUzMSAxMC43MjU2IDguODA3NjIgMTAuMDEzIDguODA3NjJDOS4zMDAzNSA4LjgwNzYyIDguNzIyNjYgOS4zODUzMSA4LjcyMjY2IDEwLjA5NzlDOC43MjI2NiAxMC44MTA2IDkuMzAwMzUgMTEuMzg4MyAxMC4wMTMgMTEuMzg4M1oiIGZpbGw9ImN1cnJlbnRDb2xvciIvPgo8cGF0aCBkPSJNMTkuNTUxMiA5LjU2NTIySDE4LjA5MjZDMTcuODY4MiA1LjQ0MTc5IDE0LjU1ODIgMi4xMzE4NCAxMC40NjI4IDEuOTA3NDNWMC40NDg4MDhDMTAuNDYyOCAwLjE5NjM1MyAxMC4yNjY1IDAgMTAuMDE0IDBDOS43NjE1NyAwIDkuNTY1MjIgMC4xOTYzNTMgOS41NjUyMiAwLjQ0ODgwOFYxLjkwNzQzQzUuNDQxNzkgMi4xMzE4NCAyLjEzMTg0IDUuNDQxNzkgMS45MDc0MyA5LjU2NTIySDAuNDQ4ODA4QzAuMTk2MzUzIDkuNTY1MjIgMCA5Ljc2MTU3IDAgMTAuMDE0QzAgMTAuMjY2NSAwLjE5NjM1MyAxMC40NjI4IDAuNDQ4ODA4IDEwLjQ2MjhIMS45MDc0M0MyLjEzMTg0IDE0LjU1ODIgNS40NDE3OSAxNy44NjgyIDkuNTM3MTcgMTguMDkyNlYxOS41NTEyQzkuNTM3MTcgMTkuODAzNiA5LjczMzUyIDIwIDkuOTg1OTcgMjBDMTAuMjM4NCAyMCAxMC40MzQ4IDE5LjgwMzYgMTAuNDM0OCAxOS41NTEyVjE4LjA5MjZDMTQuNTMwMiAxNy44NjgyIDE3Ljg0MDEgMTQuNTU4MiAxOC4wNjQ1IDEwLjQ2MjhIMTkuNTIzMUMxOS43NzU2IDEwLjQ2MjggMTkuOTcxOSAxMC4yNjY1IDE5Ljk3MTkgMTAuMDE0QzE5Ljk3MTkgOS43NjE1NyAxOS44MDM2IDkuNTY1MjIgMTkuNTUxMiA5LjU2NTIyWk0xMC4wMTQgMTcuMTk0OUM2LjAzMDg2IDE3LjE5NDkgMi44MDUwNSAxMy45NjkxIDIuODA1MDUgMTAuMDE0QzIuODA1MDUgNi4wNTg5MSA2LjAzMDg2IDIuODA1MDUgMTAuMDE0IDIuODA1MDVDMTMuOTk3MiAyLjgwNTA1IDE3LjIyMyA2LjAzMDg2IDE3LjIyMyAxMC4wMTRDMTcuMjIzIDEzLjk5NzIgMTMuOTY5MSAxNy4xOTQ5IDEwLjAxNCAxNy4xOTQ5WiIgZmlsbD0iY3VycmVudENvbG9yIi8+Cjwvc3ZnPgo=);
$icon-minus: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iMiIgdmlld0JveD0iMCAwIDEzIDIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xMS41ODAyIDEuMDAyNzhIMC41MDQ5NUMwLjIzNTY0MyAxLjAwMjc4IDAgMC43Njg4MDIgMCAwLjUwMTM5MkMwIDAuMjMzOTgzIDAuMjM1NjQzIDAgMC41MDQ5NSAwSDExLjU4MDJDMTEuODQ5NSAwIDEyLjA4NTEgMC4yMzM5ODMgMTIuMDg1MSAwLjUwMTM5MkMxMi4wODUxIDAuNzY4ODAyIDExLjg0OTUgMS4wMDI3OCAxMS41ODAyIDEuMDAyNzhaIiBmaWxsPSJjdXJyZW50Q29sb3IiLz4KPC9zdmc+Cg==);
$icon-plus: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMyAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTExLjU4MDIgNS40ODE4OUg2LjUzMDY5VjAuNTAxMzkzQzYuNTMwNjkgMC4yMzM5ODMgNi4yOTUwNSAwIDYuMDI1NzQgMEM1Ljc1NjQ0IDAgNS41MjA3OSAwLjIzMzk4MyA1LjUyMDc5IDAuNTAxMzkzVjUuNDgxODlIMC41MDQ5NTFDMC4yMzU2NDQgNS40ODE4OSAwIDUuNzE1ODggMCA1Ljk4MzI5QzAgNi4yNTA3IDAuMjM1NjQ0IDYuNDg0NjggMC41MDQ5NTEgNi40ODQ2OEg1LjUyMDc5VjExLjQ2NTJDNS41MjA3OSAxMS43MzI2IDUuNzU2NDQgMTEuOTY2NiA2LjAyNTc0IDExLjk2NjZDNi4yOTUwNSAxMS45NjY2IDYuNTMwNjkgMTEuNzMyNiA2LjUzMDY5IDExLjQ2NTJWNi40ODQ2OEgxMS41NDY1QzExLjgxNTggNi40ODQ2OCAxMi4wNTE1IDYuMjUwNyAxMi4wNTE1IDUuOTgzMjlDMTIuMDUxNSA1LjcxNTg4IDExLjg0OTUgNS40ODE4OSAxMS41ODAyIDUuNDgxODlaIiBmaWxsPSJjdXJyZW50Q29sb3IiLz4KPC9zdmc+Cg==);

// Body
$body-color: $color-darkblue;

// Links
$link-hover-color: $color-skyblue-200;

// Typography
$font-weight-normal: 500;
$font-weight-medium: 500;
$font-weight-bold: 700;

//Buttons
$btn-font-weight: 700;
$btn-font-size: $small-font-size;
$btn-line-height: $line-height-base;

$btn-padding: 16px;
$btn-padding-y: $btn-padding;
$btn-padding-x: $btn-padding;
$btn-padding-y-sm: $btn-padding;
$btn-padding-x-sm: $btn-padding;
$btn-padding-y-lg: $btn-padding;
$btn-padding-x-lg: $btn-padding;

$btn-box-shadow: none;

// Utils
$sizes: (
  33: 33%,
  66: 66%,
);

$spacers: (
  1: 1px,
  2: 2px,
  3: 3px,
  4: 4px,
  8: 8px,
  16: 16px,
  24: 24px,
  32: 32px,
  48: 48px,
  64: 64px,
  72: 72px,
  96: 96px,
  112: 112px,
  128: 128px,
  144: 144px,
);

// Grid columns
$grid-gutter-width: 20px;

// Navbar
$nav-link-padding-y: $font-size-base/2;
$nav-link-padding-x: $font-size-base;

// Tooltip
$tooltip-opacity: 1;
$tooltip-bg: transparent;
$tooltip-color: $color-darkblue;
$tooltip-arrow-color: rgba(255, 255, 255, 0.9);
